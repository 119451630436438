
const iOS = 'iOS';
const ANDROID = 'ANDROID';
const WEB = 'WEB';

export type UserDevice = typeof iOS | typeof ANDROID | typeof WEB;

export const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
export const notMobile = () => !isMobile();
export const isAndroid = () => /Android/i.test(navigator.userAgent);
export const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);

export const getUserDevice = (): UserDevice => {
    if (isAndroid()) return ANDROID;
    if (isIOS()) return iOS;
    return WEB;
};