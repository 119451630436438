export const COGIFY_WEBSITE_URL = 'https://cogify.io';
export const ICARDS_WEBSHOP_URL = 'https://icards.link/';


// timeouts
export const API_CALL_DEBOUNCE_TIME_IN_MS = 500;
export const SWITCH_CALL_DEBOUNCE_TIME_IN_MS = 500;
export const API_CALL_LONG_DEBOUNCE_TIME_IN_MS = 1000;
export const API_CALL_WALLET_DOWNLOAD_DEBOUNCE_TIME_IN_MS = 1000;
export const TIME_BEFORE_PROFILE_VIEW_IS_TRACKED = 4000;
export const ALERT_TIMEOUT = 10000;

export const MAX_LINKS = 10;
export const MAX_CONTACTS = 12;

export const ICARDS_URL = 'icards.link';
export const ICARDS_FULL_URL = 'https://icards.link';
export const ICARDS_SUPPORT_EMAIL = 'support@icards.link';
export const WHATSAPP_URL = 'https://api.whatsapp.com/send/?phone=41765237718';

export const CONTACT_PHONE_NUMBER = '+41 76 523 77 18';
export const CONTACT_PHONE_NUMBER_RAW = '41765237718';


// alerts
export const SUCCESS = 'success';
export const ERROR = 'error';

export const MY_CARDS_ANALYTICS_FILTER = 'MY_CARDS';
export const COMPANY_CARDS_ANALYTICS_FILTER = 'COMPANY_CARDS';