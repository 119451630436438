import {useCallback, useState} from 'react';
import {downloadGoogleWalletPass, saveWalletCard} from '../../api/walletCard';
import { Basic } from '../../types/cardProfile';
import { WalletStyle } from '../../types/walletCard';
import {downloadFileInNewTab} from '../../utils/download';
import {isAndroid, isIOS} from '../../utils/device';

const useSaveWalletCardController = (id: number, walletCardId: number | undefined) => {
    const [walletLoading, setWalletLoading] = useState<boolean>(false);
    const [addToWalletLoading, setAddToWalletLoading] = useState<boolean>(false);

    const saveWalletCardInfo = (wallet?: Basic, walletStyle?: WalletStyle , profileImage?: File, coverImage?: File ) => {
        setWalletLoading(true);
        const formData = new FormData();

        const walletData = {
            ...wallet,
            ...walletStyle
        };
    
        formData.append('wallet', JSON.stringify(walletData));
        
        if (profileImage) {
            formData.append('profileImage',profileImage);
        }
    
        if (coverImage) {
            formData.append('coverImage',coverImage);
        }
         
        saveWalletCard(id, formData)
            .then((res)=>{
                if (res.error) alert(res.error.response.data.error);
            }
            ).finally(() => setWalletLoading(false));  
    };

    const handleAddWallet = useCallback(() => {
        if (!walletCardId || addToWalletLoading) return;

        const isAndroidDevice = isAndroid();
        const isIOSDevice = isIOS();

        if (isAndroidDevice) {
            setAddToWalletLoading(true);
            downloadGoogleWalletPass(walletCardId)
                .then(res => {
                    if (res.error) return;
                    downloadFileInNewTab(res.data);
                })
                .finally(() => setAddToWalletLoading(false));

        } else if (isIOSDevice) {
            // not yet supported
        }
    }, [walletCardId]);

    return {
        saveWalletCardInfo,
        walletLoading,
        handleAddWallet,
        addToWalletLoading
    };
};

export default useSaveWalletCardController;
