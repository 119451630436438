import React, { FC, useState } from 'react';
import FileInput from './FileInput';
import styles from '../fileInput/file-input.module.scss';
import i18n from '../../../translations/i18n';
import { ReactComponent as AvatarIcon } from '../../../assets/icons/general/avatar.svg';

interface AvatarImageInputProps {
    id: string;
    onPhotoChange: (newPhoto: File) => void;
    profilePhoto?: string
    handleRemove?: () => void
}

const AvatarImageInput: FC<AvatarImageInputProps> = ({ id, onPhotoChange, profilePhoto, handleRemove }) => {
    const [key, setKey] = useState<number>(0);

    const handleFileChange = (file: File) => {
        onPhotoChange(file);
        setKey(prevKey => prevKey + 1);
    };
    
    return (
        <div className={styles.UploadHeader} key={key}>
            <div className={styles.avatarContainer}>
                {profilePhoto ? (
                    <img
                        className={styles.avatarImage}
                        src={profilePhoto}
                        alt="Avatar Preview"
                    />
                ) : (
                    <AvatarIcon />
                )}
            </div>
            <div className={styles.profileBtnContainer}>
                <FileInput
                    id={id}
                    label={profilePhoto ? i18n.t('profile.general.changePic') : i18n.t('profile.general.uploadPic')}
                    labelClassName={styles.customLabel}
                    onFileChange={handleFileChange}
                />
                {profilePhoto &&
                    <div onClick={handleRemove} className={styles.deleteBtn}>
                        {i18n.t('common.remove')}
                    </div>
                }
            </div>
        </div>
    );
};

export default AvatarImageInput;
