
export const getAutocompleteStyle = () => ({
    width: 240,
    backgroundColor: '#FFFFFF',
    '& .MuiInputBase-root': {
        height: '48px'
    },
    '& .MuiInputBase-input': {
        position: 'absolute',
        width: 'fit-content !important'
    }
});

export const getPaperStyle = () => ({
    width: '100%',
    textAlign: 'left'
});
