import {useState} from 'react';
import {
    changeUserPassword,
    getUserInfoByKeycloakUserId,
    saveUserSettings
} from '../../../api/user';
import {selectKkUser, selectUser, storeUser, storeUserPhoto} from '../../../store/slices/userSessionSlice';
import {useAppDispatch, useAppSelector} from '../../../store/appDispatch';
import {UserChangePassword} from '../../../types/user';
import {InitialChangePasswordState} from './YourAccount';
import i18n from '../../../translations/i18n';
import {InputMessage} from '../../common/input/Input';
import {notBlank} from '../../../utils/common';
import {UserConverter} from '../../../converters/api/UserConverter';

const useYourAccountController = (
    setShowChangePwdInputs: (arg: boolean) => void,
    setUserChangePassword: (arg: UserChangePassword) => void,
) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [responseMsg, setResponseMsg] = useState<InputMessage | undefined>(undefined);
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const kkUser = useAppSelector(selectKkUser);

    const changeUserPasswordApi = (changePasswordData: UserChangePassword) => {
        setLoading(true);
        changeUserPassword(user.id, changePasswordData)
            .then((res) => {
                const error = res.error;
                if (error) {
                    const errMsg = error.response?.status === 400 ? error.response?.data?.error : i18n.t('messages.internalServerError');
                    setResponseMsg({error: true, msg: errMsg});
                } else {
                    setShowChangePwdInputs(false);
                    setUserChangePassword(InitialChangePasswordState);
                    setResponseMsg({error: false, msg: i18n.t('messages.passwordHasBeenChanged')});
                }
            })
            .finally(() => setLoading(false));
    };

    const saveUserSettingsApi = (profileBlob: Blob | undefined, fullname: string, language: string | undefined) => {
        setLoading(true);
        const formData = new FormData();
        profileBlob && formData.append('profileImage', profileBlob);
        notBlank(fullname) && formData.append('fullname', fullname);
        language && formData.append('language', language);

        saveUserSettings(user.id, formData)
            .then((res)=>{
                if (res.error) {
                    alert(res.error.response.data.error);
                } else {
                    getUserInfoByKeycloakUserId(kkUser.id)
                        .then(res => {
                            if (res.error) return;
                            dispatch(storeUser(UserConverter.convertFromUserApiToUser(res.data)));
                            profileBlob && dispatch(storeUserPhoto(URL.createObjectURL(profileBlob)));
                        });
                }
            })
            .finally(() => setLoading(false));
    };

    return {
        saveUserSettingsApi,
        changeUserPasswordApi,
        loading,
        responseMsg
    };
};

export default useYourAccountController;