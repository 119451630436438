import { FC } from 'react';
import { useParams } from 'react-router-dom';
import styles from './preview.module.scss';
import { WalletCardBasic, WalletStyle } from '../../../types/walletCard';
import useQrcodeController from '../../common/shareModal/useQrcodeController';
import i18n from '../../../translations/i18n';

interface PreviewProps {
    walletBasic: WalletCardBasic,
    coverPhoto: string
    profilePhoto: string
    walletStyle?: WalletStyle
    uuid: string,
    newProfilePhoto?: string
    newCoverPhoto?: string
}

const Preview: FC<PreviewProps> = ({ walletBasic, coverPhoto, profilePhoto, walletStyle, uuid }) => {
    const { qrCode } = useQrcodeController(uuid);

    const { id } = useParams();
    if (!id) return <></>;
    return (
        <>
            <div className={styles.container} style={{ backgroundColor: walletStyle?.cardBgColor }}>
                <div className={styles.top} >
                    <div className={styles.headerContainer} style={{ backgroundColor: walletStyle?.cardBgColor }}>
                        {profilePhoto &&
                            <div className={styles.companyLogoWrapper}>
                                <img className={styles.companyLogo} src={profilePhoto} alt="Avatar Preview"/>
                            </div>
                        }
                    </div>
                    <div className={styles.company} style={{ color: walletStyle?.cardTextColor }}>{walletBasic.company}</div>
                    <div className={styles.coverContainer}>
                        {coverPhoto ? (
                            <div className={styles.coverPhoto}>
                                <img className={styles.cover} src={coverPhoto} alt="cover Preview" />
                            </div>
                        ) :
                            <div className={styles.blankCover}></div>}
                    </div>
                    <div className={styles.infosContainer}>
                        <div className={styles.infoContainer}>
                            <span style={{ color: walletStyle?.cardTextColor }}>{i18n.t('common.name').toUpperCase()}</span>
                            <div className={styles.info} style={{ color: walletStyle?.cardTextColor }}>{walletBasic?.fullname?.toUpperCase() || ''}</div>
                        </div>
                        <div className={styles.infoContainer}>
                            <span style={{ color: walletStyle?.cardTextColor }}>{i18n.t('common.title').toUpperCase()}</span>
                            <div className={styles.info} style={{ color: walletStyle?.cardTextColor }}>{walletBasic?.position?.toUpperCase() || ''}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.bottom} >
                    <div className={styles.qrcode}>
                        {qrCode &&
                            <img src={qrCode} alt="QR code" className={styles.qrCodeImg} />
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Preview;