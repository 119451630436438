import React, {FC, ReactNode, useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {notBlank} from '../../utils/common';
import {acceptCompanyInvitation} from '../../api/company';

const JOIN_QUERY_PARAM = 'join';

interface JoinCompanyWrapperProps {
    children: ReactNode;
}

const JoinCompanyWrapper: FC<JoinCompanyWrapperProps> = ({children}) => {
    const { search } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);

    const joinCompanyCode = queryParams?.get(JOIN_QUERY_PARAM);
    const joinCompanyCodeExists = !!(joinCompanyCode && notBlank(joinCompanyCode));

    useEffect(() => {
        joinCompanyCodeExists && acceptCompanyInvitation(joinCompanyCode);
    }, [joinCompanyCodeExists]);

    return (
        <>
            {children}
        </>
    );
};

export default JoinCompanyWrapper;