import { configDefaultWithToken, configForImages, deleteApiCall, getApiCall, patchApiCall } from './base';

const walletCardUrl = '/api/wallet/card-profile';
const walletUrl = 'api/wallet';
const walletGooglePassByIdUrl = (id: number) => `${walletUrl}/google-pass/${id}`;

export const walletCardProfileByIdURL = `${walletUrl}/profile-image/card-profile`;
export const walletCardCoverByIdURL = `${walletUrl}/cover-image/card-profile`;

export const getWalletCardById = async (id: string) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await getApiCall(`${walletCardUrl}/${id}`, config);
};

export const saveWalletCard = async (id: number, data: FormData) => {
    const config = {
        ...(await configForImages())
    };

    return await patchApiCall(`${walletCardUrl}/${id}`, data, config);
};

export const deleteWalletCoverImgById = async (id: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await deleteApiCall(`${walletCardCoverByIdURL}/${id}`, config);
};

export const deleteWalletProfileImgById = async (id: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await deleteApiCall(`${walletCardProfileByIdURL}/${id}`, config);
};

export const downloadGoogleWalletPass = async (walletId: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await getApiCall(walletGooglePassByIdUrl(walletId), config);
};