import React, {FC} from 'react';
import useCompanyCardsController from './useCompanyCardsController';
import Cards from './Cards';

const CompanyCards: FC = () => {
    const {
        apiFilter, setApiFilter, cardProfilesPagination, handleDeleteCardProfile
    } = useCompanyCardsController();

    return (
        <Cards
            apiFilter={apiFilter}
            setApiFilter={setApiFilter}
            cardProfilesPagination={cardProfilesPagination}
            handleDeleteCardProfile={handleDeleteCardProfile}
        />
    );
};

export default CompanyCards;