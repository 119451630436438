import React, { FC, useState } from 'react';
import FileInput from './FileInput';
import { ReactComponent as UploadFileIcon } from '../../../assets/icons/general/uploadFile.svg';
import styles from '../fileInput/file-input.module.scss';
import i18n from '../../../translations/i18n';

interface CoverImageInputProps {
    id: string;
    onPhotoChange: (newPhoto: File) => void;
    handleRemove?: (e: React.MouseEvent) => void
    coverPhoto?: string
    label: string
    btnText: string
}

const CoverImageInput: FC<CoverImageInputProps> = ({ id, onPhotoChange, coverPhoto, label, btnText, handleRemove }) => {
    const [key, setKey] = useState<number>(0);

    const handleFileChange = (file: File) => {
        onPhotoChange(file);
        setKey(prevKey => prevKey + 1);
    };

    return (
        <div key={key}>
            <FileInput id={id} label={label} className={styles.coverImage} onFileChange={handleFileChange}>
                <div className={styles.coverContainer}>
                    {coverPhoto ? (
                        <div className={styles.coverExist}>
                            <img className={styles.coverImage} src={coverPhoto} alt="Cover Preview" />
                            <div className={styles.btnContainer}>
                                <div className={styles.coverBtn}>{btnText}</div>
                                {coverPhoto &&
                                    <div onClick={handleRemove} className={styles.deleteBtn}>{i18n.t('common.remove')}</div>
                                }
                            </div>

                        </div>
                    ) : (
                        <>
                            <UploadFileIcon />
                            <div>
                                {i18n.t('profile.general.uploadFile')} <span> {i18n.t('profile.general.dragAndDrop')}</span>
                            </div>
                            <span>{i18n.t('profile.general.pngJpgGif')}</span>
                        </>
                    )}
                </div>
            </FileInput>
        </div>
    );
};

export default CoverImageInput;