export const routes = {
    previewCardProfile: '/:uuid',
    analytics: '/',
    myCards: '/my-cards',
    companyCards: '/company-cards',
    profile: '/profile/:id',
    walletCard: '/wallet-card/:id',
    settings: '/settings'
};

export const navigateTo = {
    previewCardProfile: '',
    analytics: routes.analytics,
    myCards: routes.myCards,
    companyCards: routes.companyCards,
    profile: '/profile',
    walletCard: '/wallet-card',
    settings: routes.settings
};