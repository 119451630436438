import React, { FC, useState } from 'react';
import styles from './general.module.scss';
import Header from '../layout/Header';
import { Input } from '../../common/input/Input';
import i18n from '../../../translations/i18n';
import DOMPurify from 'dompurify';
import CoverImageInput from '../../common/fileInput/CoverImageInput';
import AvatarImageInput from '../../common/fileInput/AvatarImageInput';
import {CardProfileBasic} from '../../../types/cardProfile';
import Divider from '../../common/divider/Divider';
import {CropperModal} from '../../imageCropper/CropperModal';
import {
    NOT_ALLOWED_SPECIAL_CHARS_FOR_NAME_REGEX
} from '../../../utils/validationUtils';

interface GeneralProps {
    profileBasic?: CardProfileBasic | undefined
    profilePhoto?: string
    coverPhoto?: string
    showGeneralHeader?: boolean
    showIcon?: boolean
    setNewProfilePhoto: React.Dispatch<React.SetStateAction<File>>
    setNewCoverPhoto: React.Dispatch<React.SetStateAction<File>>
    setIsDirty: React.Dispatch<React.SetStateAction<boolean>>
    setBasicData: React.Dispatch<React.SetStateAction<any>>
    handleDeleteCoverImage?: (e: React.MouseEvent) => void
    handleRemoveProfile?: () => void
    newProfilePhoto?: File
    newCoverPhoto?: File
    isWalletCard?: boolean
}

const General: FC<GeneralProps> = ({
    handleDeleteCoverImage, handleRemoveProfile, profileBasic, profilePhoto, coverPhoto, showGeneralHeader,
    showIcon, setNewProfilePhoto, setNewCoverPhoto, setIsDirty, setBasicData, newProfilePhoto, newCoverPhoto, isWalletCard
}) => {
    const [isExtended, setIsExtended] = useState<boolean>(false);
    const [cropperModal, setCropperModal] = useState<{ show: boolean, image: string }>({show: false, image: ''});
    const [cropperCoverModal, setCropperCoverModal] = useState<{ show: boolean, image: string }>({show: false, image: ''});

    const handleProfilePhotoChange = (newPhoto: File) => {
        setCropperModal({show: true, image: URL.createObjectURL(newPhoto as Blob)});
    };

    const handleCoverPhotoChange = (newCoverPhoto: File) => {
        setCropperCoverModal({show: true, image: URL.createObjectURL(newCoverPhoto as Blob)});
    };

    const handleCloseCropperModal = () => setCropperModal({show: false, image: ''});

    const handleCloseCropperCoverModal = () => setCropperCoverModal({show: false, image: ''});

    const handleOnSaveImage = (croppedImg: File) => {
        setNewProfilePhoto(croppedImg);
        setIsDirty(true);
        handleCloseCropperModal();
    };

    const handleOnSaveCoverImage = (croppedImg: File) => {
        setNewCoverPhoto(croppedImg);
        setIsDirty(true);
        handleCloseCropperCoverModal();
    };

    const handleInputChange = (field: string, value: string) => {
        const sanitizedValue = DOMPurify.sanitize(value);
        setBasicData((prevData: CardProfileBasic) => ({ ...prevData, [field]: sanitizedValue }));
        setIsDirty(true);
    };

    const handleExtend = () => {
        setIsExtended(prevState => !prevState);
    };

    return (
        <div className={`${styles.container} ${isExtended ? styles.extended : styles.collapsed}`}>
            <Header showIcon={showIcon} title={i18n.t('common.general')} onExtend={handleExtend} isExtended={isExtended} isToggled/>
            {isExtended && (
                <>
                    <form className={styles.form}>
                        <Divider/>
                        <CropperModal
                            isOpen={cropperModal.show}
                            image={cropperModal.image}
                            onClose={handleCloseCropperModal}
                            onSaveImage={handleOnSaveImage}
                            cropShape={'round'}
                            cropHeight={130}
                            cropWidth={130}
                        />
                        {showGeneralHeader &&
                            <AvatarImageInput
                                id='avatarImage'
                                handleRemove={handleRemoveProfile}
                                onPhotoChange={handleProfilePhotoChange}
                                profilePhoto={newProfilePhoto ? URL.createObjectURL(newProfilePhoto) : profilePhoto}
                            />
                        }
                        <div className={styles.flexInput}>
                            <Input
                                notAllowedSpecialChars={NOT_ALLOWED_SPECIAL_CHARS_FOR_NAME_REGEX}
                                label={i18n.t('labels.fullname')}
                                value={profileBasic?.fullname}
                                type="text"
                                onChange={(e) => handleInputChange('fullname', e.target.value)}
                            />
                            {!isWalletCard &&
                                <Input
                                    label={i18n.t('labels.position')}
                                    value={profileBasic?.position}
                                    type="text"
                                    onChange={(e) => handleInputChange('position', e.target.value)}
                                />
                            }
                        </div>
                        <div className={styles.flexInput}>
                            {isWalletCard &&
                                <Input
                                    label={i18n.t('labels.position')}
                                    value={profileBasic?.position}
                                    type="text"
                                    onChange={(e) => handleInputChange('position', e.target.value)}
                                />
                            }
                            <Input
                                label={i18n.t('labels.company')}
                                value={profileBasic?.company}
                                type="text"
                                onChange={(e) => handleInputChange('company', e.target.value)}
                            />
                            {!isWalletCard &&
                                <Input
                                    label={i18n.t('labels.department')}
                                    value={profileBasic?.department}
                                    type="text"
                                    onChange={(e) => handleInputChange('department', e.target.value)}
                                />
                            }
                        </div>
                        {!showGeneralHeader &&
                            <>
                                <div className={styles.label}>{i18n.t('common.companyLogo')}</div>
                                <AvatarImageInput
                                    id='companyLogo'
                                    handleRemove={handleRemoveProfile}
                                    onPhotoChange={handleProfilePhotoChange}
                                    profilePhoto={newProfilePhoto ? URL.createObjectURL(newProfilePhoto) : profilePhoto}
                                />
                            </>
                        }
                        <CropperModal
                            isOpen={cropperCoverModal.show}
                            image={cropperCoverModal.image}
                            onClose={handleCloseCropperCoverModal}
                            onSaveImage={handleOnSaveCoverImage}
                            cropShape={'rect'}
                            cropHeight={128}
                            cropWidth={340}
                        />
                        <CoverImageInput
                            id='coverImage'
                            label='Cover'
                            btnText={i18n.t('profile.general.changeCover')}
                            handleRemove={handleDeleteCoverImage}
                            coverPhoto={newCoverPhoto ? URL.createObjectURL(newCoverPhoto) : coverPhoto}
                            onPhotoChange={handleCoverPhotoChange}
                        />
                    </form>
                </>
            )}
        </div>
    );
};

export default General;
