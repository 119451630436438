import i18n from '../../translations/i18n';
import styles from '../../components/profile/appearance/appearance.module.scss';

export const getColorOptions = () =>{
    return [
        { color: '#242222', text: i18n.t('profile.colors.default'), className: styles.default },
        { color: '#D52739', text: i18n.t('profile.colors.red'), className: styles.redColor },
        { color: '#FA9C3C', text: i18n.t('profile.colors.yellow'), className: styles.yellowColor },
        { color: '#62B138', text: i18n.t('profile.colors.green'), className: styles.greenColor },
        { color: '#6946BB', text: i18n.t('profile.colors.purple'), className: styles.purpleColor },
        { color: '', text: i18n.t('profile.colors.custom'), className: styles.customColor }
    ];
};

export const getDefaultColors = (color : string) => {
    return {
        cardBgColor: '#ffffff',
        cardTextColor: color,
        btnBgColor: color,
        btnTextColor: '#ffffff',
        iconsBgColor: color,
        iconsColor: '#ffffff',
        coverBgColor: color,
        companyBgColor: '#ffffff',
        companyTextColor: color
    };
};

export const getWalletDefaultColors = (color : string) => {
    return {
        cardBgColor: '#ffffff',
        cardTextColor: color,
    };
};