import {Dispatch, FC, SetStateAction, useMemo, useState} from 'react';
import Header from '../layout/Header';
import styles from './appearance.module.scss';
import i18n from '../../../translations/i18n';
import { useThemeContext } from '../../../context/theme/ThemeProvider';
import { getColorOptions, getDefaultColors, getWalletDefaultColors } from '../../../utils/appearance/appearanceUtils';
import CustomColors from './customColors/CustomColors';
import { WalletStyle } from '../../../types/walletCard';
import { CardProfileStyle } from '../../../types/cardProfile';

interface AppearanceProps {
    showColor ?: boolean
    showIcon?: boolean
    setIsDirty: Dispatch<SetStateAction<boolean>>
    profileStyle?: CardProfileStyle | WalletStyle
    setProfileStyle: Dispatch<SetStateAction<any>>
    setSelectedColors : Dispatch<SetStateAction<any>>
    selectedColors?: WalletStyle | CardProfileStyle 
    isCustom: boolean;
    setIsCustom: Dispatch<SetStateAction<any>>
}

const Appearance: FC<AppearanceProps> = ({
    showColor, showIcon, setIsDirty, profileStyle, setProfileStyle, setSelectedColors, selectedColors, isCustom, setIsCustom
}) => {
    const [isExtended, setIsExtended] = useState<boolean>(false);

    const { setThemeColor } = useThemeContext();
    const colorOptions = useMemo(() => getColorOptions(),[]);

    const handleExtend = () => {
        setIsExtended(prevIsExtended => !prevIsExtended);
    };

    const handleColorClick = (color: string) => {
        const isCustomColor = color === '';
        setThemeColor(color);
        setIsCustom( isCustomColor);
        setIsDirty(true);
        if (isCustomColor) {
            setSelectedColors(profileStyle);
        } else {
            setSelectedColors(showIcon ? getDefaultColors(color) : getWalletDefaultColors(color));
        }
    };

    return (
        <div className={styles.container}>
            <Header
                showIcon={showIcon}
                title={i18n.t('common.appearance')}
                onExtend={handleExtend}
                isExtended={isExtended}
                isToggled
            />
            {isExtended && 
                <form className={styles.form}>
                    <div className={styles.colorsContainer}>
                        {colorOptions.map((option, index) => (
                            <div key={index} className={styles.colorContainer} onClick={() => handleColorClick(option.color)} tabIndex={0}>
                                <div className={`${option.className} ${styles.color}`}></div>
                                <div>{option.text}</div>
                            </div>
                        ))}
                    </div>
                    {isCustom &&
                        <CustomColors
                            showColor={showColor}
                            profileStyle={selectedColors}
                            setProfileStyle={setProfileStyle}
                        />
                    }
                </form>
            }
        </div>
    );
};

export default Appearance;