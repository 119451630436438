import React, {useEffect, useState} from 'react';
import {initOptions, keycloakClient} from './keycloak/Keycloak';
import {ReactKeycloakProvider} from '@react-keycloak/web';
import PageRouter from './routes/PageRouter';
import './assets/css/globals.scss';
import { Suspense } from 'react';
import {Provider} from 'react-redux';
import store from './store/store';
import { ConfirmProvider } from './context/confirm/confirmProvider';
import {SessionProvider} from './context/SessionProvider';
import {toggleLanguage} from './translations/i18nUtils';
import i18n from './translations/i18n';
import {AlertProvider} from './context/AlertProvider';

function App() {
    const [language, setLanguage] = useState(i18n.locale);

    const handleToggleLanguage = (lang: string) => {
        const langLowerCase = lang.toLowerCase();
        i18n.locale = langLowerCase;
        setLanguage(langLowerCase);
        toggleLanguage(langLowerCase);
    };

    useEffect(() => {
        const currentLanguage = i18n.locale;

        if (currentLanguage !== language) {
            toggleLanguage(language);
        }
    }, [language]);

    return (
        <Provider store={store}>
            <ReactKeycloakProvider
                authClient={keycloakClient}
                initOptions={initOptions}
            >
                <>
                    <SessionProvider toggleLanguage={handleToggleLanguage}>
                        <AlertProvider>
                            <ConfirmProvider>
                                <Suspense fallback={<div></div>}>
                                    <PageRouter />
                                </Suspense>
                            </ConfirmProvider>
                        </AlertProvider>
                    </SessionProvider>
                </>
            </ReactKeycloakProvider>
        </Provider>
    );
}

export default App;
