import {CompanyUser, User} from '../../types/user';
import {CompanyConverter} from './CompanyConverter';
import {Company} from '../../types/company';

export const UserConverter = {
    convertFromUserApiToUser(data: any): User | null {
        if (!data) return null;

        const companies: Company[] = CompanyConverter.convertFromCompanyApiToCompanies(data.companies);

        return {
            id: data.id,
            email: data.userEmail,
            language: data.language,
            fullname: data.fullname,
            accountType: data.accountType,
            profilePhotoPath: data.profilePhotoPath,
            companies: companies,
            activeCompany: data.activeCompany
        };
    },
    convertFromCompanyMemberApiToCompanyUser(data: any): CompanyUser | undefined {
        if (!data) return undefined;

        return {
            id: data.id,
            email: data.userEmail,
            fullname: data.fullname,
            accountType: data.accountType,
            profilePhotoPath: data.profilePhotoPath
        };
    }
};