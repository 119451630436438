import {useCallback, useEffect, useState} from 'react';
import {useAppSelector} from '../../store/appDispatch';
import {selectUser} from '../../store/slices/userSessionSlice';
import {UserCardProfileConverter} from '../../converters/api/UserCardProfileConverter';
import {UserCardProfileFilter, UserCardProfilesPagination} from '../../types/userCardProfile';
import {deleteCardProfileById} from '../../api/cardProfile';
import {defaultUserCardProfileFilter} from './myCardsUtils';
import {FilerConverter} from '../../converters/api/FilterConverter';
import {getMyCardCardProfilesControllerApi} from '../../api/apiController';

const useMyCardsController = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [apiFilter, setApiFilter] = useState<UserCardProfileFilter>(defaultUserCardProfileFilter);
    const [cardProfilesPagination, setCardProfilesPagination] = useState<UserCardProfilesPagination | null>(null);
    const [reloadCards, setReloadCards] = useState<number>(0);

    const user = useAppSelector(selectUser);

    const getUserCardProfilesApi = useCallback(() => {
        setLoading(true);
        getMyCardCardProfilesControllerApi(user.id, FilerConverter.convertUserCardProfileFilterToCardProfileApiFilter(apiFilter, defaultUserCardProfileFilter))
            .then(res => {
                setCardProfilesPagination(
                    UserCardProfileConverter.convertFromUserCardProfileApiToUserCardProfilesPagination(res.data)
                );
            })
            .finally(() => setLoading(false));
    }, [user, apiFilter, user.activeCompany]);

    useEffect(() => {
        if (!user || loading) return;
        getUserCardProfilesApi();
    }, [user, apiFilter, reloadCards, user.activeCompany]);

    const handleDeleteCardProfile = async (cardProfileId: number) => {
        return await deleteCardProfileById(cardProfileId).then(() => getUserCardProfilesApi());
    };

    const reloadCardsApi = () => setReloadCards(prevState => prevState + 1);

    return {
        loading,
        apiFilter,
        setApiFilter,
        cardProfilesPagination,
        handleDeleteCardProfile,
        reloadCardsApi
    };
};

export default useMyCardsController;