import {configDefaultWithToken, configForImages, deleteApiCall, getApiCall, patchApiCall, postApiCall} from './base';
import {UserChangePassword} from '../types/user';

const userUrl = '/api/user';
const userKeycloakUrl = `${userUrl}/keycloak`;
const changePasswordUrl = `${userUrl}/change-password`;
const userProfileImageUrl = `${userUrl}/profile-image`;
const userSettingsUrl = `${userUrl}/settings`;
const usersBySameDomainUrl = `${userUrl}/same-domain`;

export const getUserInfoByKeycloakUserId = async (keycloakUserId: string) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await getApiCall(`${userKeycloakUrl}/${keycloakUserId}`, config);
};

export const changeUserPassword = async (userId: number, data: UserChangePassword) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(`${changePasswordUrl}/${userId}`, data, config);
};

export const getUserProfileImageById = async (id: number) => {
    const config = {
        ...(await configDefaultWithToken()),
        responseType: 'arraybuffer'
    };

    return await getApiCall(`${userProfileImageUrl}/${id}`, config);
};

export const saveUserSettings = async (id: string, data: FormData) => {
    const config = {
        ...(await configForImages()),
    };

    return await patchApiCall(`${userSettingsUrl}/${id}`, data, config);
};

export const deleteUserAccount = async (id: string) => {
    const config = {
        ...(await configDefaultWithToken()),
    };

    return await deleteApiCall(`${userUrl}/${id}`, config);
};

export const deleteUserProfileImgById = async (id: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await deleteApiCall(`${userProfileImageUrl}/${id}`, config);
};

export const getUserAssignList = async (id: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await getApiCall(`${usersBySameDomainUrl}/${id}`, config);
};
